<template>
  <div class="page_bg">
    <!-- 顶部 card-->
    <div class="bg_top_linear-gradient">
      <!-- 顶部返回 -->
      <div class="back_top_name flex-r-sb-c">
        <van-icon name="arrow-left" @click="backPath" />
        <div class="top_name">账期结算方案选择</div>
        <div class="top_icon" />
      </div>
    </div>
    <!-- 月份展示 -->
    <div class="order_bill_box bill_month" style="height: 5.33rem;">
      <div class="bill_box_title">选定分期</div>
      <van-row>
        <van-col v-for="(month,month_index) in monthList" :key="month_index" :span="8">
          <div :class="billFrom.month === month.id?'bill_month bill_month_choosed ':'bill_month'"
               @click="changeMonth(month.id)"
          >
            {{ month.name }}
          </div>
        </van-col>
      </van-row>
    </div>
    <!-- 方案类型（>6） -->
    <div v-if="billFrom.month > 5" class="order_bill_box bill_types">
      <div class="bill_box_title">方案类型</div>
      <van-row>
        <van-col v-for="(type,type_index) in typeList" :key="type_index" :span="8">
          <div :id="(billFrom.month === 6 || billFrom.month === 18) && type.id === '2' ? 'bill_type_no' : ''"
               :class="billFrom.type === type.id?'bill_type bill_type_choosed ':'bill_type'"
               @click="chooseType(type.id)"
          >
            <van-icon v-if="type_index === 0" name="peer-pay" />
            <van-icon v-if="type_index === 1" name="debit-pay" />
            <van-icon v-if="type_index === 2" name="paid" />
            <span>{{ type.name }}</span>
          </div>
        </van-col>
      </van-row>
    </div>
    <!-- 保证金比例（>6） -->
    <div v-if="billFrom.month > 5 && billFrom.type !== '3'" class="order_bill_box bill_month bill_types bill_cash">
      <div class="bill_box_title">保证金比例</div>
      <van-row>
        <van-col v-for="(percent,percent_index) in percentList" :key="percent_index" :span="8">
          <div :id="(billFrom.type === '2' && percent.id > '10') || billFrom.type === '3' ? 'bill_cash_no' : ''"
               :class="billFrom.percent === percent.id?'bill_month bill_month_choosed ':'bill_month'"
               @click="chooseCash(percent.id)"
          >
            {{ percent.name }}
          </div>
        </van-col>
      </van-row>
    </div>
    <!-- 还款方案 -->
    <div class="order_bill_box bill_case" style="margin-bottom:2rem">
      <div class="bill_box_title">还款方案</div>
      <!-- 1-5 -->
      <div v-if="billFrom.month <6" class="bill_case_items">
        <van-cell-group>
          <van-cell title="利息金额" :value="'￥' + billFrom.interest" />
          <van-cell title="应还款总额" :value="'￥' + billFrom.payMoney" value-class="case_item_red" />
          <van-cell v-if="billFrom.month >3" title="月还款总额"
                    :value="'￥' + billFrom.repaymentAmounts[0].resultValue + '/月'"
          />
          <van-cell title="应还款方式" :value="'￥' + repaymentLists[billFrom.repayment]" />
        </van-cell-group>
      </div>
      <div v-if="billFrom.month >5 && billFrom.type !== '3'" class="bill_case_items">
        <van-cell-group>
          <van-cell title="保证金金额" :value="'￥' + billFrom.bond" value-class="case_item_red" />
          <van-cell title="手续费金额">
            <template #right-icon>
              <van-switch v-if="billFrom.type > 1" v-model="billFrom.exemptHandlingFee"
                          :disabled="billFrom.type === '3'" size="0.6rem" active-color="#0767AB"
              />
              <span v-else class="van-cell__value">￥{{ billFrom.handlingFee }}</span>
            </template>
          </van-cell>
          <van-cell title="利息总额" :value="'￥' + billFrom.interest" />
          <van-cell title="分期利率" :value="billFrom.interestRate" />
          <van-cell title="首月还款" :value="agreementTenancyList[billFrom.agreementTenancy]" />
          <van-cell title="还款方式" :value="repaymentLists[billFrom.repayment]"
                    @click="(billFrom.month === 12 || billFrom.month === 24)&&billFrom.type === '2' ?show = true:show = false"
          />
        </van-cell-group>
      </div>
    </div>
    <!-- 还款方式选择 -->
    <van-action-sheet v-model="show" :actions="repaymentList" @select="choosePayway" />
    <!-- 底部提交 -->
    <div class="order_bill_sub">
      <van-submit-bar :price="price" button-text="提交订单" button-color="#0767AB" @submit="confirmCase" />

    </div>
    <!-- loading遮罩层 -->
    <van-overlay :show="overlayShow" @click="overlayShow = false">
      <div class="overlay_loading">
        <van-loading color="#eee" size="0.6rem" type="spinner" vertical>方案提交中...</van-loading>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import './index.scss'
import { quoteStaging_calcQuoteStagingResDto, quoteStaging_confirmQuoteStagingResDto } from '@/api/order'
export default {
  data() {
    return {
      overlayShow: false,
      price: '',
      show: false,
      monthList: [
        { id: 1, name: '1个月' },
        { id: 2, name: '2个月' },
        { id: 3, name: '3个月' },
        { id: 4, name: '4个月' },
        { id: 5, name: '5个月' },
        { id: 6, name: '6个月' },
        { id: 12, name: '12个月' },
        { id: 18, name: '18个月' },
        { id: 24, name: '24个月' }
      ],
      // typeList: [{ id: '1', name: '标准方案' }, { id: '2', name: '非标准方案' }, { id: '3', name: '自定义方案' }],
      typeList: [{ id: '1', name: '标准方案' }, { id: '3', name: '自定义方案' }],
      percentList: [
        { id: '0', name: '0%' },
        { id: '10', name: '10%' },
        { id: '20', name: '20%' },
        { id: '30', name: '30%' },
        { id: '40', name: '40%' },
        { id: '50', name: '50%' }
      ],
      agreementTenancyList: {
        '1': '期初还款',
        '2': '期末还款',
        '9': '--'
      },
      repaymentLists: {
        '1': '逐月规则还款',
        '2': '逐月差异还款',
        '3': '全额到期还款'
      },
      repaymentList: [{ id: '1', name: '逐月规则还款' }, { id: '2', name: '逐月差异还款' }],
      billFrom: {
        totalInstallment: '0',
        month: 1, // 选定分期
        type: '1', // 方案类型
        percent: '0', // 保证金比例
        bond: '0.00', // 保证金金额
        handlingFee: '0.00', // 手续费金额
        exemptHandlingFee: false, // 是否免手续费
        interestRate: '0.00', // 分期利率
        interest: '0.00', // 利息总额
        payMoney: '0.00', // 应还款总额
        agreementTenancy: '1', // 首月还款
        repayment: '3', // 还款方式
        repaymentAmounts: [{ resultValue: '0.00', resultName: 'null' }, { resultValue: '0.00', resultName: 'null' }], // 还款金额
        industry: '' // 销售分类
      },
      isOpenPayway: false,
      paywayList: {
        '1': '逐月规则还款',
        '2': '逐月差异还款',
        '3': '全额到期还款'
      }
    }
  },
  created() {
    this.price = parseInt(this.$route.query.price.replace('.', ''))
    this.billFrom.totalInstallment = this.$route.query.price
    this.billFrom.industry = this.$route.query.flag
    this.changeMonth(1)
  },
  methods: {
    backPath() { window.history.back() },
    // 切换月份
    changeMonth(id) {
      this.billFrom.month = id
      // 重置方案类型
      if (id === 6 || id === 18) {
        this.billFrom.exemptHandlingFee = false
        this.chooseType('2')
      } else {
        this.countCase()
      }
      // 影响首月还款 影响还款方式
      if (this.billFrom.type === '1') {
        this.billFrom.agreementTenancy = '2'
        this.billFrom.repayment = '1'
        this.countCase()
      } else if (this.billFrom.type === '1') {
        this.billFrom.agreementTenancy = '1'
        this.countCase()
      }
      if (id < 6) {
        this.billFrom.type = '1'
        //  影响还款方式
        if (id < 4) {
          this.billFrom.repayment = '3'
          this.countCase()
        }
      }
    },
    // 方案类型
    chooseType(id) {
      // 受到选定分期影响
      if ((this.billFrom.month === 6 || this.billFrom.month === 18) && id === '2') {
        this.billFrom.type = '1'
        this.countCase()
      } else {
        this.billFrom.type = id
        this.countCase()
      }
      // 重置保证金
      if (id === '2') {
        this.chooseCash('0')
      }
      // 影响首月还款 影响还款方式
      if (id === '1') {
        this.billFrom.agreementTenancy = '2'
        this.billFrom.repayment = '1'
        this.countCase()
      } else if (id === '2') {
        this.billFrom.agreementTenancy = '1'
        this.countCase()
      }
    },
    // 保证金比例
    chooseCash(id) {
      // 受到方案类型影响
      if (this.billFrom.type === '2' && id > '10') {
        this.billFrom.percent = '0'
        this.countCase()
      } else {
        this.billFrom.percent = id
        this.countCase()
      }
    },
    // 是否免手续费
    exemptHandlingFeeChange(e) {
      this.billFrom.exemptHandlingFee = e.target.value
      this.countCase()
    },
    // 还款方式
    choosePayway(item) {
      console.log(item)
      if (item.id === '3') {
        console.log('item.id === 3')
      } else {
        this.billFrom.repayment = item.id
        this.isOpenPayway = false
        this.countCase()
      }
      this.show = false
    },
    // 计算方案
    countCase() {
      quoteStaging_calcQuoteStagingResDto(this.billFrom).then(info => {
        this.billFrom.payMoney = info.data.payMoney
        this.billFrom.interestRate = info.data.interestRate
        this.billFrom.interest = info.data.interest
        this.billFrom.bond = info.data.bond
        this.billFrom.handlingFee = info.data.handlingFee
        if (info.data.repaymentAmounts == null) {
          this.billFrom.repaymentAmounts = [{ resultValue: '0.00', resultName: 'null' }, { resultValue: '0.00', resultName: 'null' }]
        } else {
          this.billFrom.repaymentAmounts = info.data.repaymentAmounts
        }
      })
    },
    // 确认方案
    confirmCase() {
      this.overlayShow = true
      quoteStaging_confirmQuoteStagingResDto(this.billFrom).then(info => {
        setTimeout(() => {
          this.loading = false
          this.backPath()
        }, 1000)
        var parms = {
          mouth: this.billFrom.month,
          way: this.paywayList[this.billFrom.repayment],
          quoteStagingKey: info.data.quoteStagingKey
        }
        if (this.billFrom.type === '3') {
          parms = {
            mouth: this.billFrom.month,
            way: '自定义还款',
            quoteStagingKey: info.data.quoteStagingKey
          }
        }
        sessionStorage.setItem('billCase', JSON.stringify(parms))
        this.overlayShow = false
      }).catch(() => {
        this.overlayShow = false
      })
    }
  }
}
</script>

<style>
</style>
